import React from 'react'
import { RxDashboard } from 'react-icons/rx'
import { AiOutlineForm, AiOutlineCalendar } from 'react-icons/ai'
import { CiSettings } from 'react-icons/ci'
import { NavLink } from 'react-router-dom';

const IntakeForm = () => {
  return (
    <div>
      <div className='w-full border'>
        <p className='text-xl px-16 py-5'>M. Saini and Associates</p>
      </div>
      <div className='grid grid-cols-4'>
        <div className='grid-span-1 text-xl bg-slate-300 px-10 py-10'>
          <h1 className='text-4xl flex justify-center py-10'>HI</h1>
          <div className='py-10 pl-5'>
            <div className='flex items-center py-5'>
              <RxDashboard />
              <NavLink to="/dashboardHome" smooth={true} duration={500}><p className='pl-3'>Dashboard</p></NavLink>
            </div>
            <div className='flex items-center py-5'>
              <AiOutlineForm />
              <p className='pl-3'>Intake Form</p>
            </div>
            <div className='flex items-center py-5'>
              <AiOutlineCalendar />
              <p className='pl-3'>Book An Appointment</p>
            </div>
            <div className='flex items-center py-5 pb-40'>
              <CiSettings />
              <p className='pl-3'>Settings</p>
            </div>
          </div>
        </div>
        <div className='grid-span-3 w-full'>

        </div>
      </div>
    </div>
  )
}

export default IntakeForm
