import React, { useEffect, useState } from 'react';
import WaterfallVid from '../assets/waterfallvid.mp4'

import { Collapse } from 'antd';
import GoToTop from './GoToTop';

const FAQ = () => {
    const [shouldAutoplay, setShouldAutoplay] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        // Adjust the threshold as needed based on your design
        const thresholdWidth = 768; // Example threshold for small screens
        setShouldAutoplay(window.innerWidth >= thresholdWidth);
      };
  
      handleResize(); // Set initial autoplay value
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  return (
    <div className='bg-[#36363665]'>
      <div className='flex justify-center'>
            <div>
                <video loop autoPlay={shouldAutoplay} muted className='bg-video'>
                    <source src={WaterfallVid} type="video/mp4"/>
                </video>
            </div>
        </div>
        <div>
            <h1 className='text-5xl pt-24 md:text-6xl text-white font-bold py-10 text-center lg:px-20 lg:text-left'>Frequently Asked Questions</h1>
        </div>
        <div className='px-10 py-10 md:py-24 lg:py-10 bg-white md:px-48'>
            <div>
                <Collapse
                size= "large"
                items={[{ key: '1', label: 'How long are sessions?', children: <p>Sessions are typically 50 to 60 minutes in length.</p>}]}/>
                <Collapse
                size= "large"
                items={[{ key: '1', label: 'What is a Virtual Clincal Mental Health Consultation?', children: <p>Prior to your inital visit, we will have you fill out our comprehensive assessment package. This provides us with an integrative look at the areas you are struggling with and determine how we can specifically help you. At the end of the consultation, we will provide you with a therapeutic proposal. It is to be noted, this is a non-diagnostic assessment.</p>}]}/>
                <Collapse
                size= "large"
                items={[{ key: '1', label: 'What is the preferred method of payment?', children: <p>Cash, Cheque, or E-Transfer.</p>}]}/>
                <Collapse
                size= "large"
                items={[{ key: '1', label: 'What are the fees for therapy?', children: <p>Regular session fees are $165.00. We continue to provide limited spots for reduced cost therapy. A verification of being a recipient of social services will be required.</p>}]}/>

                <Collapse
                size= "large"
                items={[{ key: '1', label: 'How do I qualify for reduced cost therapy?', children: <p>Anyone who is non-insured and/or low income background may qualify for reduced cost therapy. There are limited spots for 50 min counselling sessions.</p>}]}/>
                <Collapse
                size= "large"
                items={[{ key: '1', label: 'Do you have a cancellation policy?', children: <p>We have a 24 hour cancellation policy. When cancelling an appointment within 24 hours of an appointment, a full session cost will be charged.</p>}]}/>
                <Collapse
                size= "large"
                items={[{ key: '1', label: 'Are you under any specific insurances?', children: <p>Therapists who are affiliated with Ontario College of Social Service Workers and Social Workers (OCSWSSW) do not require to be under Psychologists or Psychiatrists. However, we also understand that some insurances do not accept receipts from a few regulatory bodies. Please check your benefit/insurance package prior to booking your first session.</p>}]}/>
                <Collapse
                size= "large"
                items={[{ key: '1', label: 'What are virtual check-ins?', children: <p>We have noticed that our regular clients may not require a full hour, therefore we offer 30 minute check-ins when needed. Fees are half of our full fee.</p>}]}/>

                <Collapse
                size= "large"
                items={[{ key: '1', label: 'Do you deal with custody access and court proceedings?', children: <p>We do not deal with custody access nor provide court documentation around the mental health of the client.</p>}]}/>
                <Collapse
                size= "large"
                items={[{ key: '1', label: 'Do you deal with WSIB?', children: <p>Yes we do provide services for clients under WSIB, however it is to be noted that we do not provide a diagnostic assessment to WSIB, but rather a summary of how treatment is going with a written consent by our clients.</p>}]}/>
                <Collapse
                size= "large"
                items={[{ key: '1', label: 'Do you provide psychological assessments of any kind?', children: <p>We are not scholarly trained for psychometric testing of any form of DSM V assessments, as you require to have obtained either PhD, DPsych, or MD in the province of Ontario. Clinicians can be trained in psychometrics however must be supervised by a registered psychologist in the province of Ontario. We do however, provide a variety of non-diagnostic assessments and questionnaires that can be provided to you to fill out and for you to share with a medical professional such as your physician.</p>}]}/>
            </div>
        </div>
        <GoToTop />
    </div>
  )
}

export default FAQ
