import React from 'react'
import { RxDashboard } from 'react-icons/rx'
import { AiOutlineForm, AiOutlineCalendar } from 'react-icons/ai'
import { CiSettings } from 'react-icons/ci'
import { NavLink } from 'react-router-dom';
import { Calendar } from 'antd';

// const onPanelChange = (value, mode) => {
//     console.log(value.format('YYYY-MM-DD'), mode);
//   };
//   const App = () => {
//     const { token } = theme.useToken();
//     const wrapperStyle = {
//       width: 300,
//       border: `1px solid ${token.colorBorderSecondary}`,
//       borderRadius: token.borderRadiusLG,
//     };
// }

const BookAppointment = () => {
  return (
    <div>
      <div className='w-full border'>
        <p className='text-xl px-16 py-5'>M. Saini and Associates</p>
      </div>
      <div className='flex'>
        <div className='w-[400px] text-xl border px-5 py-10'>
          <h1 className='text-4xl flex justify-center py-10'>HI</h1>
          <div className='py-10 pl-5'>
            <div className='flex items-center py-5'>
              <RxDashboard />
              <NavLink to="/dashboardHome" smooth={true} duration={500}><p className='pl-3'>Dashboard</p></NavLink>
            </div>
            <div className='flex items-center py-5'>
              <AiOutlineForm />
              <NavLink to="/intakeForm" smooth={true} duration={500}><p className='pl-3'>Intake Form</p></NavLink>
            </div>
            <div className='flex items-center py-5'>
              <AiOutlineCalendar />
              <NavLink to="/bookAppointment" smooth={true} duration={500}><p className='pl-3'>Book An Appointment</p></NavLink>
            </div>
            <div className='flex items-center py-5 pb-64'>
              <CiSettings />
              <p className='pl-3'>Settings</p>
            </div>
          </div>
        </div>
        <div className='w-full px-10 py-10'>
            <p className='text-3xl border-b'>Select A Service</p>
            <div className='pt-5'>
                <button className='serviceButton'>Adult Therapy</button>
                <button className='serviceButton'>Teen Therapy</button>
                <button className='serviceButton'>Couples Therapy</button>
                <button className='serviceButton'>Family Therapy</button>
                <button className='serviceButton'>Adult Therapy</button>
                <button className='serviceButton'>Teen Therapy</button>
                <button className='serviceButton'>Couples Therapy</button>
                <button className='serviceButton'>Family Therapy</button>
            </div>
            <div className='py-10'>
                <p className='text-3xl border-b'>Select A Day and Time</p>
                <div className='flex'>
                    <div className='w-full'>
                        <div className='w-[300px] pt-5'>
                            <Calendar fullscreen={false}/>
                        </div>
                    </div>
                    <div className='flex px-10 pt-5 justify-center h-full'>
                        <button className='serviceButton w-[125px]'>11:00am</button>
                        <button className='serviceButton w-[125px]'>11:00am</button>
                        <button className='serviceButton w-[125px]'>11:00am</button>
                        <button className='serviceButton w-[125px]'>11:00am</button>
                    </div>
                </div>
            </div>
            <div>
                <button className='serviceButton w-full'>Book</button>
            </div>
        </div>
        </div>
    </div>
  )
}

export default BookAppointment
