import React, { useEffect, useState } from 'react';
import WaterfallVid from '../assets/waterfallvid.mp4'
import { NavLink } from 'react-router-dom';

const HomepageVid = () => {
  const [shouldAutoplay, setShouldAutoplay] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Adjust the threshold as needed based on your design
      const thresholdWidth = 768; // Example threshold for small screens
      setShouldAutoplay(window.innerWidth >= thresholdWidth);
    };

    handleResize(); // Set initial autoplay value

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='w-full h-screen'>
        <div className='flex justify-center'>
            <div>
                <video loop autoPlay={shouldAutoplay} playsinline muted className='bg-video'>
                    <source src={WaterfallVid} type="video/mp4"/>
                </video>
            </div>
        </div>
        <div className='top-[50%] absolute pl-10 w-full'>
          <h1 className='text-white text-2xl md:text-6xl font-bold'>Mental Wellness Services</h1>
          <h1 className='text-white text-2xl md:text-4xl pb-1'>Virtual Psychotherapy Sessions</h1>
          <NavLink to="/About" smooth={true} duration={500} className='transition duration-200'><button className='bg-[#255C4A] px-5 py-2 rounded-lg text-white mb-10 text-lg hover:bg-[#194033] transition duration-300'>Learn More</button></NavLink>
        </div>
    </div>
  )
}

export default HomepageVid
