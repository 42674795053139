import React, { useEffect, useState } from 'react';
import WaterfallVid from '../assets/waterfallvid.mp4'
import GoToTop from './GoToTop'

const PrivacyPolicy = () => {
    const [shouldAutoplay, setShouldAutoplay] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        // Adjust the threshold as needed based on your design
        const thresholdWidth = 768; // Example threshold for small screens
        setShouldAutoplay(window.innerWidth >= thresholdWidth);
      };
  
      handleResize(); // Set initial autoplay value
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  return (
    <div className='h-[100%] w-full bg-[#36363665] text-black'>
        <div className='flex justify-center'>
            <div>
                <video loop autoPlay={shouldAutoplay} muted className='bg-video'>
                    <source src={WaterfallVid} type="video/mp4"/>
                </video>
            </div>
        </div>
        <div>
            <h1 className='text-5xl pt-24 md:text-6xl text-white font-bold py-10 text-center lg:px-20 lg:text-left'>Privacy Policy</h1>
        </div>
        <div className='px-10 py-10 bg-white md:px-48'>
          <h1 className='pt-10 text-3xl font-semibold'>Overview</h1>
            <p className='pt-5 text-lg pr-10'>M. Saini and Associates is committed to safeguarding the privacy of our clients and ensuring the secure exchange of information during therapy services provided through our online platform. We recognize the importance of privacy and confidentiality and take extensive measures to protect your personal information. The document describes our privacy policies.</p>
            <h1 className='pt-10 text-3xl font-semibold'>Use and Disclosure of Personal Information</h1>
            <p className='pt-5 text-lg'>M. Saini and Associates will not disclose your personal information to any third party without your informed and written consent, except in specific circumstances:</p>
            <div className='pl-5'>
                <p className='py-2 text-lg'>•	When there is a legal or ethical obligation to inform a potential victim of violence that a client intends to harm</p>
                <p className='py-2 text-lg'>•	When it is necessary to inform an appropriate family member, healthcare professional, or law enforcement in cases where a client expresses an intention to end their life</p>
                <p className='py-2 text-lg'>•	In response to a court order requiring the release of a client’s file</p>
                <p className='py-2 text-lg'>•	If there is suspicion of a child being at risk or in need of protection due to neglect or abuse</p>
                <p className='pt-2 text-lg'>•	In cases of sexual abuse by a regulated health professional, we will report the incident to the professional’s regulatory body
                </p>
            </div>

            <h1 className='pt-10 text-3xl font-semibold'>What is Personal Information</h1>
            <p className='pt-5 text-lg'>Individual-related details encompass information pertaining to a recognizable person and involve details concerning the individual's personal attributes (such as name, date of birth, residential address, and phone number), health-related information (including the presenting issue, health history, health services utilized, and social circumstances), as well as activities and perspectives (such as expressed opinions or evaluations). It's important to note that personal information differs from business-related information (like a person's business address and phone number), which isn't safeguarded by privacy legislation. In the course of their responsibilities, we engage various consultants and agencies who may have restricted access to the personal information within our possession. These professionals and entities comprise bookkeepers, accountants, legal advisors, office maintenance and cleaning personnel, computer consultants, credit card companies, financial institutions, marketing staff, and website managers. We make efforts to limit their access to the personal information we possess to the greatest extent possible and have implemented a confidentiality agreement with them.</p>

            <h1 className='pt-10 text-3xl font-semibold'>Online Policy</h1>
            <p className='pt-5 text-lg'>M. Saini and Associates have made high-quality technology a priority to ensure the most secure exchange of information between therapists and clients engaging in distance counselling through our online platform. Online counselling is conducted through a very secure website, and no information remains on your computer or the counsellor’s computer. Although specific measures have been taken to protect the information that will be shared between you and your counsellor, the privacy and confidentiality of computer-mediated communication cannot be guaranteed. It is important to have access to a private, distraction free environment during online sessions.</p>

            <p className='pt-5 text-lg'>By engaging in online therapy sessions with M. Saini and Associates, you consent to the collection, transmission, and storage of your personal information within the constraints of this privacy policy. M. Saini and Associates reserves the right to update and revise this policy to reflect changes in our practices and legal requirements. We recommend checking this policy periodically for any updates.</p>
        </div>
        <GoToTop />
    </div>
  )
}

export default PrivacyPolicy
