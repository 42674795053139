import React from 'react'
import { RxDashboard } from 'react-icons/rx'
import { CiSettings } from 'react-icons/ci'
import { AiOutlineForm, AiOutlineCalendar } from 'react-icons/ai'
import { NavLink } from 'react-router-dom';
import { BsFilePerson } from 'react-icons/bs'
import { Space, Table, Tag } from 'antd';

const Clients = () => {
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (text) => <a href="https://www.google.ca/">{text}</a>,
        },
        {
          title: 'Age',
          dataIndex: 'age',
          key: 'age',
        },
        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
        },
        {
          title: 'Tags',
          key: 'tags',
          dataIndex: 'tags',
          render: (_, { tags }) => (
            <>
              {tags.map((tag) => {
                let color = tag.length > 5 ? 'geekblue' : 'green';
                if (tag === 'loser') {
                  color = 'volcano';
                }
                return (
                  <Tag color={color} key={tag}>
                    {tag.toUpperCase()}
                  </Tag>
                );
              })}
            </>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <a href="https://www.google.ca/"><AiOutlineForm style={{width: "40px"}}/></a>
              {/* <a>Delete</a> */}
            </Space>
          ),
        },
      ];
      const data = [
        {
          key: '1',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park',
          tags: ['nice', 'developer'],
        },
        {
          key: '2',
          name: 'Jim Green',
          age: 42,
          address: 'London No. 1 Lake Park',
          tags: ['potato'],
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sydney No. 1 Lake Park',
          tags: ['cool', 'teacher'],
        },
      ];
  return (
    <div>
      <div className='w-full border'>
        <p className='text-xl px-16 py-5'>M.R. Saini and Associates</p>
        </div>
        <div className='flex'>
        <div className='w-[400px] text-xl border px-5 py-10'>
          <h1 className='text-4xl flex justify-center py-10'>HI</h1>
          <div className='py-10 pl-5'>
            <div className='flex items-center py-5'>
              <RxDashboard />
              <NavLink to="/adminDashboard" smooth={true} duration={500}><p className='pl-3'>Dashboard</p></NavLink>
            </div>
            <div className='flex items-center py-5'>
              <BsFilePerson />
              <NavLink to="/clients" smooth={true} duration={500}><p className='pl-3'>Clients</p></NavLink>
            </div>
            <div className='flex items-center py-5'>
              <AiOutlineCalendar />
              <NavLink to="/appointments" smooth={true} duration={500}><p className='pl-3'>Appointments</p></NavLink>
            </div>
            <div className='flex items-center py-5 pb-64'>
              <CiSettings />
              <p className='pl-3'>Settings</p>
            </div>
          </div>
        </div>
        <div className=' w-full px-10 py-10'>
            <p className='text-3xl'>Clients</p>
            <div className='pt-5'>
                <Table columns={columns} dataSource={data} />
            </div>
        </div>
      </div>
    </div>
  )
}

export default Clients
