import React, { useEffect, useState } from 'react';
import WaterfallVid from '../assets/waterfallvid.mp4'
import children from '../assets/happy_children.jpg'
import family from '../assets/family_stock.jpg'
import sad from '../assets/sad_stock.jpg'
import GoToTop from './GoToTop'



const Services = () => {
  const [shouldAutoplay, setShouldAutoplay] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Adjust the threshold as needed based on your design
      const thresholdWidth = 768; // Example threshold for small screens
      setShouldAutoplay(window.innerWidth >= thresholdWidth);
    };

    handleResize(); // Set initial autoplay value

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='bg-[#36363665]'>
      <div className='flex justify-center'>
            <div>
                <video loop autoPlay={shouldAutoplay} muted className='bg-video'>
                    <source src={WaterfallVid} type="video/mp4"/>
                </video>
            </div>
      </div>
      <div>
            <h1 className='text-5xl pt-24 md:text-6xl text-white font-bold py-10 text-center lg:px-20 lg:text-left'>Services</h1>
      </div>
      <div className='px-10 py-10 md:px-48 bg-white'>
        <h1 className='py-5 text-3xl font-semibold'>Top Specialties</h1>
        <div className='grid grid-cols-1 lg:grid-cols-3 text-lg space-y-5 md:space-y-0'>
          <div>
            <div className='w-full h-[300px] md:w-[410px] md:h-[300px]' style={{
              backgroundImage: `url(${children})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
              }}>
            </div>
            <h2 className='flex justify-center font-medium'>Child Therapy</h2>
          </div>
          <div>
            <div className='w-full h-[300px] md:w-[410px] md:h-[300px]' style={{
              backgroundImage: `url(${family})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
              }}>
            </div>
            <h2 className='flex justify-center font-medium'>Family Conflict</h2>
          </div>
          <div>
            <div className='w-full h-[300px] md:w-[410px] md:h-[300px]' style={{
              backgroundImage: `url(${sad})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
              }}>
            </div>
            <h2 className='flex justify-center font-medium'>Depression</h2>
          </div>
          

        </div>
        <h1 className='py-5 text-3xl font-semibold'>Expertise</h1>
        <div className='grid md:grid-cols-2 space-y-5 md:space-y-0 lg:grid-cols-4 text-lg'>
          <div>
            <p>• ADHD</p>
            <p>• Anger Management</p>
            <p>• Anxiety</p>
            <p>• Behavioural Issues</p>
            <p>• Chronic Impulsivity</p>
            <p>• Coping Skills</p>
            <p>• Divorce</p>
          </div>
          <div>
            <p>• Domestic Violence</p>
            <p>• Emotional Disturbance</p>
            <p>• Grief</p>
            <p>• Self-Harming</p>
            <p>• Substance Use</p>
            <p>• Suicidal Ideation</p>
            <p>• Teen Violence</p>
          </div>
          <div>
            <p>• Impulse Control Disorders</p>
            <p>• Mood Disorders</p>
            <p>• Parenting</p>
            <p>• Peer Relationships</p>
            <p>• Relationship Issues</p>
            <p>• School Issues</p>
            <p>• Self Esteem</p>
          </div>
          <div>
            <p>• Adoption</p>
            <p>• Workplace Burnout</p>
            <p>• Post-Partum Depression</p>
            <p>• Aging</p>
            <p>• Social Anxiety Disorder</p>
            <p>• Life Stresses</p>
          </div>
        </div>
      </div>
      <GoToTop />
    </div>
  )
}

export default Services
