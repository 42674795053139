import React from 'react';
import { NavLink } from 'react-router-dom';

const Login = () => {
  return (
    <div className='w-full'>
      <div className='w-[450px] h-[450px] mx-auto my-20 bg-[#e0e0e0] rounded-xl'>
        <h1 className='text-4xl font-bold text-white border-b-2 pb-8 border-gray-300 pl-[175px] pt-8'>Login</h1>
        <form className='mx-10 mt-[20px]'>
            <div className='txt-field'>
                <input type="email" placeholder=" "/>
                <label>Email</label>
            </div>
            <div className='txt-field'>
                <input type="password" placeholder=" "/>
                <label>Password</label>
            </div>
            <h2 className='text-[15px] text-[#a6a6a6] font-medium transition duration-200 hover:text-[#2F6F50]'><a href="https://www.google.ca/">Forgot Password</a></h2>
            <div className='pt-5'>
                <button className= 'w-[100%] height-[50px] bg-[#2F6F50] rounded-xl text-white font-bold py-3 transition duration-200 hover:bg-[#25573f]' type="button">Login</button>
            </div>
            <h2 className='text-[#676768] font-medium text-[16px] py-7 flex justify-center'>Not a member? <NavLink to="/getStarted" className='ml-1 text-[#2F6F50] transition duration-200 hover:text-[#A3C83A]'>Signup</NavLink></h2>
        </form>
      </div>
    </div>
  )
}

export default Login
