import React from 'react'
import { RxDashboard } from 'react-icons/rx'
import { AiOutlineCalendar } from 'react-icons/ai'
import { CiSettings } from 'react-icons/ci'
import { NavLink } from 'react-router-dom';
import { BsFilePerson } from 'react-icons/bs'

const AdminDashboard = () => {
  return (
    <div>
        <div className='w-full border'>
        <p className='text-xl px-16 py-5'>M. Saini and Associates</p>
        </div>
        <div className='flex'>
        <div className='w-[400px] text-xl border px-5 py-10'>
          <h1 className='text-4xl flex justify-center py-10'>HI</h1>
          <div className='py-10 pl-5'>
            <div className='flex items-center py-5'>
              <RxDashboard />
              <NavLink to="/adminDashboard" smooth={true} duration={500}><p className='pl-3'>Dashboard</p></NavLink>
            </div>
            <div className='flex items-center py-5'>
              <BsFilePerson />
              <NavLink to="/clients" smooth={true} duration={500}><p className='pl-3'>Clients</p></NavLink>
            </div>
            <div className='flex items-center py-5'>
              <AiOutlineCalendar />
              <NavLink to="/appointments" smooth={true} duration={500}><p className='pl-3'>Appointments</p></NavLink>
            </div>
            <div className='flex items-center py-5 pb-64'>
              <CiSettings />
              <p className='pl-3'>Settings</p>
            </div>
          </div>
        </div>
        <div className=' w-full px-10 py-10'>
          <p className='text-3xl'>Hello, Admin</p>
          <div>
            <p className='pt-10 pb-3 text-xl'>Upcoming Appointments</p>
            <div className='border rounded-md w-full h-[200px] text-center'>
              <p className='py-20'>No appointments yet</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard
