import React, { useEffect, useState } from 'react';
import WaterfallVid from '../assets/waterfallvid.mp4'
import GoToTop from './GoToTop'

const LandAcknowledgement = () => {
    const [shouldAutoplay, setShouldAutoplay] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        // Adjust the threshold as needed based on your design
        const thresholdWidth = 768; // Example threshold for small screens
        setShouldAutoplay(window.innerWidth >= thresholdWidth);
      };
  
      handleResize(); // Set initial autoplay value
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  return (
    <div className='h-[100%] w-full bg-[#36363665] text-black'>
        <div className='flex justify-center'>
            <div>
                <video loop autoPlay={shouldAutoplay} muted className='bg-video'>
                    <source src={WaterfallVid} type="video/mp4"/>
                </video>
            </div>
        </div>
        <div>
            <h1 className='text-3xl px-8 pt-24 md:text-6xl text-white font-bold py-10 md:px-20'>Land Acknowledgement</h1>
        </div>
        <div className='px-10 py-10 bg-white md:px-64 text-center md:py-40'>

            <p className='text-lg md:text-xl leading-10'>M. Saini and Associates recognizes that its work, and the work of its community partners take place on traditional Indigenous territories across the province of Ontario. We acknowledge that there are 46 treaties and other agreements that cover the territory now called Ontario. We are thankful to be able to work and live in these territories. We are thankful to the First Nations, Metis and Inuit people who have cared for these territories since time immemorial and who continue to contribute to the strength of Ontario and to all communities across the province. It is our privilege to collaborate with Indigenous clients, stakeholders and communities throughout the various territories.</p>

            <p className='pt-10 text-lg md:text-xl leading-10'>
            M. Saini and Associates would also like to acknowledge that its office is located in Toronto and on the traditional territory of many nations including the Mississaugas of the Credit, the Anishnabeg, the Chippewa, the Haudenosaunee and the Wendat peoples and is now the home to many diverse First Nations, Inuit and Metis peoples. We also acknowledge that Toronto is covered by Treaty 13 signed with the Mississaugas of the Credit, and the Williams Treaties signed with multiple Mississaugas and Chippewa bands.</p>
        </div>
        <GoToTop />
    </div>
  )
}

export default LandAcknowledgement
