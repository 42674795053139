import React, { useEffect, useState, useRef } from 'react';
import WaterfallVid from '../assets/waterfallvid.mp4';
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import GoToTop from './GoToTop';

const GetStarted = () => {
  const form = useRef();
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm("service_s0ybuz1", "template_vuo0w7z", form.current, "GQBrEH7ikI0Vd9A1g")
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
       Swal.fire({
        icon: "success",
        title: "Message Sent Successfully"
      })
    }, function(error) {
       console.log('FAILED...', error);
       Swal.fire({
        icon: "error",
        title: "Ooops, something went wrong",
        text: error.text,
      })
    });
  };

  const [shouldAutoplay, setShouldAutoplay] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Adjust the threshold as needed based on your design
      const thresholdWidth = 768; // Example threshold for small screens
      setShouldAutoplay(window.innerWidth >= thresholdWidth);
    };

    handleResize(); // Set initial autoplay value

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='bg-[#36363665]'>
      <div className='flex justify-center'>
            <div>
                <video loop autoPlay={shouldAutoplay} muted className='bg-video'>
                    <source src={WaterfallVid} type="video/mp4"/>
                </video>
            </div>
      </div>
      <div>
        <h1 className='text-5xl pt-24 md:text-6xl text-white font-bold py-10 text-center lg:px-20 lg:text-left'>Get Started</h1>
      </div>
      <div className='px-10 py-5 md:px-48 md:py-32 lg:py-5 bg-white'>
        <div className='my-5'>
          <h1 className='text-3xl flex justify-center font-mont font-medium text-center'>Book your free 15 minute consultation!</h1>
          <h1 className='text-xl flex justify-center py-5 font-mont font-medium text-center'>Send us a message using this contact form and we will arrange a consultation appointment with you!</h1>
          <div className='flex justify-center'>
            <form className='flex flex-col max-w-[1000px] w-full' ref={form} onSubmit={handleOnSubmit}>
              <div className='flex space-x-5'>
                <input className='bg-gray-300 p-2 rounded-md w-full' type="text" placeholder='First Name' name='firstName' required/>
                <input className='bg-gray-300 p-2 rounded-md w-full' type="text" placeholder='Last Name' name='lastName' required/>
              </div>
              <input className='my-4 p-2 bg-gray-300 rounded-md' type="email" placeholder='Email' name='email' required/>
              <textarea className='bg-gray-300 p-2 rounded-md' name="message" rows="10" placeholder='Message' required></textarea>
              <input type="submit" value="Submit" className='text-white rounded-md transition duration-200 bg-[#286749] px-4 py-3 my-4 mx-auto flex justify-center w-full cursor-pointer'></input>
            </form>
          </div>
        </div>
      </div>
      <GoToTop />
    </div>
  )
}

export default GetStarted
