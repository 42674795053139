import React from 'react'
import auntyPic from '../assets/aunty.jpeg'
import { NavLink } from 'react-router-dom';

const Vision = () => {
  return (
    <div className='bg-white flex justify-center'>
      <div className='bg-[#2F6F50] w-full h-full mx-8 my-5 rounded-lg grid md:grid-cols-3'>
          <div className='w-[80%] md:px-0 text-white mx-auto my-auto text-center md:text-left md:col-span-2'>
              <h1 className='text-3xl md:text-4xl pb-5 font-bold py-10 md:py-0'>Our Vision</h1>
              <p className='text-md md:text-[22px] pb-5 pt-2'>Our mission is to empower individuals to achieve their full potential by providing compassionate and evidence-based psychotherapy services. We are dedicated to creating a safe, supportive, and non-judgmental environment where our clients can explore their thoughts, feelings, and behaviors, and develop the tools and skills necessary to overcome their challenges and improve their mental health and well-being. Our ultimate goal is to help our clients live happier, healthier, and more fulfilling lives.</p>
              <NavLink to="/About" smooth={true} duration={500} className='transition duration-200'>
                  <button className='bg-[#255C4A] px-5 py-2 rounded-lg text-white text-lg hover:bg-[#194033] transition duration-300'>Learn More About Us</button>
              </NavLink>
          </div>
          <div className='flex justify-center items-center mx-auto text-center text-white space-y-1 py-10'>
            <div>
              <img alt="" src={auntyPic} className='mx-auto pb-1'/>
              <p className='font-bold text-lg'>Manisha Saini</p>
              <p className=''>Registered Social Worker, MSW, RSW, Psychotherapist</p>
              </div>
          </div>
      </div>













      {/* <div className='w-[97%] lg:h-[500px] md:h-[850px] h-[100%] bg-[#2F6F50] rounded-lg mb-5'>
        <div className='grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 text-white'>
          <div className='grid col-span-2'>
            <div className='px-20 pt-24'>
              <h1 className='text-3xl pb-5 font-bold'>Our Vision</h1>
              <p className='text-xl pb-5'>Our mission is to empower individuals to achieve their full potential by providing compassionate and evidence-based psychotherapy services. We are dedicated to creating a safe, supportive, and non-judgmental environment where our clients can explore their thoughts, feelings, and behaviors, and develop the tools and skills necessary to overcome their challenges and improve their mental health and well-being. Our ultimate goal is to help our clients live happier, healthier, and more fulfilling lives.
              </p>
              <NavLink to="/About" smooth={true} duration={500} className='transition duration-200'><button className='bg-[#255C4A] px-5 py-2 rounded-lg text-white mb-10 text-lg hover:bg-[#194033] transition duration-300'>Learn More About Us</button></NavLink>
          
            </div>
          </div>
          <div className='flex justify-center px-28 text-center'>
            <div className='pt-6 pb-5'>
              <img alt="" src={auntyPic} className='pb-2'/>
              <p className='pt-1'>Manisha Saini</p>
              <p className='w-[270px]'>Registered Social Worker, MSW, RSW, Psychotherapist</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Vision
