import React, { useEffect, useState } from 'react';
import WaterfallVid from '../assets/waterfallvid.mp4'
import auntyPic from '../assets/auntySquare.jpeg'
import GoToTop from './GoToTop'

const About = () => {
    const [shouldAutoplay, setShouldAutoplay] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        // Adjust the threshold as needed based on your design
        const thresholdWidth = 768; // Example threshold for small screens
        setShouldAutoplay(window.innerWidth >= thresholdWidth);
      };
  
      handleResize(); // Set initial autoplay value
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
    <div className='h-[100%] w-full bg-[#36363665] text-black'>
        <div className='flex justify-center'>
            <div>
                <video loop controls={false} autoPlay={shouldAutoplay} muted className='bg-video'>
                    <source src={WaterfallVid} type="video/mp4"/>
                </video>
            </div>
        </div>
        <div>
            <h1 className='text-5xl pt-24 md:text-6xl text-white font-bold py-10 text-center lg:px-20 lg:text-left'>About Your Psychotherapist</h1>
        </div>
        <div className='px-10 py-10 bg-white md:px-48'>
            <div className='grid grid-cols-1 lg:grid-cols-3'>
                <div className='col-span-2 '>
                    <h1 className='pt-5 text-3xl font-medium'>About</h1>
                    <p className='pt-5 text-lg pr-10'>After 30 years of full time work in social services sector, I have started a private practice offering experienced personalized counselling for various age groups such as teens (13 to 17), and adults (18 to 65). I am a registered social worker and a psychotherapist with the Ontario College of Social Workers and Social Services Workers since 1991. I have a masters degree in social work with specialization in family and child care. I also completed a diploma in gerontology and postgraduate diploma in management studies. I am DBT trained from Adler institute, with additional training in trauma, abuse, and violence from Guelph University. I am currently a part-time student at University of Guelph working towards advanced certificate course in couples therapy.</p>
                </div>
                <div className='col-span-1 mx-auto flex justify-center'>
                    <div>
                        <div className='flex justify-center'>
                        <img src={auntyPic} alt="" className='pt-8'/>
                        </div>
                        <h1 className='pt-2 text-center'>Manisha Saini, Registered Social Worker, MSW, RSW, Psychotherapist</h1>
                    </div>
                </div>
            </div>
            <h1 className='pt-10 text-3xl font-medium'>Why Start Now as a Psychotherapist?</h1>
            <p className='pt-5 text-lg pr-10'>My journey of life is enriched with tremendous experiences both good and bad which has given me wisdom to understand and assist people in coping with life’s challenges. My perception, ability to adapt and understand through positive or negative experiences has enriched manifold. Whenever I have counselled individuals dealing with challenges, the most important thing one wishes for is empathy, trust and understanding. I understand that sometimes we need to voice our hurt, our pain, our frustration and I have the knowledge and skills to heal people who are confused, distraught, unheard and misunderstood and I wish to make a positive difference in their lives with my skills.</p>
            <h1 className='pt-10 text-3xl font-medium'>Experience</h1>
            <p className='pt-5 text-lg'>I have worked as a Social Worker at Children’s Aid Society/Child Protection Services, I have over 29 years experience of working in a social services department at Regional Municipality of York. I am aware of the great lengths of what it takes to become an advocate for a family, for a child, for a woman and anyone in the Front line. I have been counselling and supervising staff who have been supporting communities with issues such as Domestic Violence, Addictions, Child abuse, Abandonment, Psychological issues, Anxiety, Bullying, Trauma, Post Traumatic Stress Injuries, settlement issues, elder abuse, neglect, loneliness and the psychological impact of the pandemic. I have started my private practice of psychotherapy and counselling services as I realize that my skills, experience and energy can be applied and beneficial for the community.</p>

            <p className='pt-5 pb-5 text-lg'>I encourage you to share your struggles with me. Our space together will be a safe space, which will help us discover your struggles and tell your story. Together we can explore what brought you to therapy and where you want to be. You will be assured of full confidentiality and a non-judgmental environment as you share your experiences and symptoms. We will establish goals in working together and provide you with authentic and genuine human interaction. We will work towards gaining inner peace and stability within your authentic self.</p>

            <p className='pt-5 pb-5 text-lg'>Employing a strengths-based, client-centered approach, I specialize in addressing diverse cultural perspectives. I draw on various modalities, including Narrative Therapy, Cognitive Behavioral Therapy (CBT), Dialectical Behavior Therapy (DBT), Eye Movement Desensitization and Reprocessing (EMDR), Emotionally Focused Therapy, Solution-Focused Approaches, and incorporate Gottman Methods and Family/Mental Systems. Collaborating with Registered Psychotherapists for supervision and consultations enhances the depth and effectiveness of my therapeutic interventions, ensuring a culturally sensitive and comprehensive approach.</p>
        </div>
        <GoToTop />
    </div>
  )
}

export default About
