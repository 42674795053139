import React from 'react'
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='w-full h-[100%] text-[#808080] bg-[#2F6F50]'>
      <div className='hidden md:flex justify-center pt-7'>
          <div>
            <NavLink to="/GetStarted" smooth={true} duration={500} className='transition duration-200 hover:text-white px-2 text-[15px] font-medium'>Contact Us</NavLink>
            |
          </div>
          <div>
            <NavLink to="/Services" smooth={true} duration={500} className='transition duration-200 hover:text-white px-2 text-[15px] font-medium'>Our Services</NavLink>
            |
          </div>
          <div>
            <NavLink to="/PrivacyPolicy" smooth={true} duration={500} className='transition duration-200 hover:text-white px-2 text-[15px] font-medium'>Privacy Policy</NavLink>
            |
          </div>
          <div>
            <NavLink to="/LandAcknowledgement" smooth={true} duration={500} className='transition duration-200 hover:text-white px-2 text-[15px] font-medium'>Land Acknowledgement</NavLink>
          </div>
      </div>

      <div className='grid md:hidden justify-center pt-7'>
          <div className='flex justify-center'>
            <NavLink to="/GetStarted" smooth={true} duration={500} className='transition duration-200 hover:text-white px-2 text-[15px] font-medium'>Contact Us</NavLink>|
            <NavLink to="/Services" smooth={true} duration={500} className='transition duration-200 hover:text-white px-2 text-[15px] font-medium'>Our Services</NavLink>
          </div>
          <div className='flex justify-center'>
            <NavLink to="/PrivacyPolicy" smooth={true} duration={500} className='transition duration-200 hover:text-white px-2 text-[15px] font-medium'>Privacy Policy</NavLink>|
            <NavLink to="/LandAcknowledgement" smooth={true} duration={500} className='transition duration-200 hover:text-white px-2 text-[15px] font-medium'>Land Acknowledgement</NavLink>
          </div>
      </div>

      
      <h2 className='text-center pb-7 font-medium text-[15px]'>&copy; 2023 M. Saini and Associates</h2>
    </div>
  )
}

export default Footer
