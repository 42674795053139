import React from "react"; 
import Navbar from "./components/Navbar";
import HomepageVid from "./components/HomepageVid";
import Home from "./components/Home";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from "./components/About";
import Services from "./components/Services";
import FAQ from "./components/FAQ";
import GetStarted from "./components/GetStarted";
import Signup from "./components/Signup";
import Login from "./components/Login";
import DashboardHome from "./components/DashboardHome";
import IntakeForm from "./components/IntakeForm";
import BookAppointment from "./components/BookAppointment";
import AdminDashboard from "./components/AdminDashboard";
import Clients from "./components/Clients";
import Appointments from "./components/Appointments";
import PrivacyPolicy from "./components/PrivacyPolicy";
import LandAcknowledgement from "./components/LandAcknowledgement";
import Vision from "./components/Vision";
// import SettingsModal from "./components/SettingsModal";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<><Navbar /><HomepageVid /><Home /><Vision /></>} />
          <Route path="/about" element={<><Navbar /><About /></>} />
          <Route path="/services" element={<><Navbar /><Services /></>} />
          <Route path="/faq" element={<><Navbar /><FAQ /></>} />
          <Route path="/getStarted" element={<><Navbar /><GetStarted /></>} />
          <Route path="/signup" element={<><Navbar /><Signup /></>} />
          <Route path="/login" element={<><Navbar /><Login /></>} />
          <Route path="/dashboardHome" element={<><DashboardHome /></>} />
          <Route path="/intakeForm" element={<><IntakeForm /></>} />
          <Route path="/bookAppointment" element={<><BookAppointment /></>} />
          <Route path="/adminDashboard" element={<><AdminDashboard /></>} />
          <Route path="/clients" element={<><Clients /></>} />
          <Route path="/appointments" element={<><Appointments /></>} />
          <Route path="/privacyPolicy" element={<><Navbar /><PrivacyPolicy /></>} />
          <Route path="/landacknowledgement" element={<><Navbar /><LandAcknowledgement /></>} />
        </Routes>
        {/* <SettingsModal/> */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
