import React from 'react'
import {ImLeaf} from 'react-icons/im'
import {AiFillHeart, AiOutlineSafety} from 'react-icons/ai'

import GoToTop from './GoToTop';

// import person1 from '../assets/people.jpeg'
// import person2 from '../assets/people1.jpeg'
// import person3 from '../assets/people2.jpeg'
// import person4 from '../assets/people3.jpeg'
// import person5 from '../assets/people4.jpeg'
// import person6 from '../assets/people5.jpeg'

const Home = () => {
  return (
    <div className='w-full h-[100%]'>
      
      <div className='bg-white px-5 flex justify-center'>
        <div className='grid md:grid-cols-3 w-full pt-5'>
          <div className='pb-5 px-3'>
            <div className='card h-[200px] text-center'>
              <div className='text-5xl text-[#A3C83A] justify-center flex'>
                <ImLeaf size={60}/>
              </div>
              <h1 className='pt-5 text-2xl font-bold'>Wellness</h1>
            </div>
          </div>
          <div className='pb-5 px-3'>
            <div className='card h-[200px] text-center'>
              <div className='text-5xl text-[#A3C83A] justify-center flex'>
                <AiFillHeart size={60}/>
              </div>
              <h1 className='pt-5 text-2xl font-bold'>Health</h1>
            </div>
          </div>
          <div className='pb-5 px-3'>
            <div className='card h-[200px] text-center'>
              <div className='text-5xl text-[#A3C83A] justify-center flex'>
                <AiOutlineSafety size={60}/>
              </div>
              <h1 className='pt-5 text-2xl font-bold'>Safety</h1>
            </div>
          </div>
        </div>
      </div>
      <GoToTop />
    </div>
  )
}

export default Home
