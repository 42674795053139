import React, {useState} from 'react'
import { NavLink } from 'react-router-dom';
import {FaBars, FaTimes} from 'react-icons/fa'
import Logo from '../assets/MSA_Logo.png'

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)
  return (
    <div className='navbar fixed w-full h-[70px] flex justify-between items-center px-10 text-white bg-[#18181849] z-10'>
        <div className='text-xl flex items-center space-x-2 font-medium cursor-pointer hover:text-gray-300 transition duration-200'>
            <img src={Logo} className='w-[60px]' alt="" />
            <NavLink to="/" smooth={true} duration={500}>M. Saini and Associates</NavLink>
        </div>

        <ul className='hidden lg:flex font-medium text-xl'>
            <li className='hover:text-gray-300 py-4 px-5 transition duration-200'>
                <NavLink to="/about" smooth={true} duration={500}>About Us</NavLink>
            </li>
            <li className='hover:text-gray-300 py-4 px-5 transition duration-200'>
                <NavLink to="/services" smooth={true} duration={500}>Services</NavLink>
            </li>
            <li className='hover:text-gray-300 py-4 px-5 transition duration-200'>
                <NavLink to="/faq" smooth={true} duration={500}>FAQ</NavLink>
            </li>
            <li className='hover:text-gray-300 py-4 px-5 transition duration-200'>
                <a href="https://calendly.com/m-sainiassociates/therapysession?month=2024-03" target="_blank" rel="noreferrer"> Get Started</a>
            </li>
        </ul>

        <div onClick={handleClick} className="lg:hidden z-20 cursor-pointer">
            {!nav ? <FaBars /> : <FaTimes />}
        </div>

        <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full z-10 h-screen bg-[#286749] flex flex-col justify-center items-center'}>
            <li className='py-6 text-4xl hover:text-gray-300 transition duration-200'>
                <NavLink to="/" smooth={true} duration={500} onClick={handleClick}>Home</NavLink>
            </li>
            <li className='py-6 text-4xl hover:text-gray-300 transition duration-200'>
                <NavLink to="/about" smooth={true} duration={500} onClick={handleClick}>About Us</NavLink>
            </li>
            <li className='py-6 text-4xl hover:text-gray-300 transition duration-200'>
                <NavLink to="/services" smooth={true} duration={500} onClick={handleClick}>Services</NavLink>
            </li>
            <li className='py-6 text-4xl hover:text-gray-300 transition duration-200'>
                <NavLink to="/faq" smooth={true} duration={500} onClick={handleClick}>FAQ</NavLink>
            </li>
            <li className='py-6 text-4xl hover:text-gray-300 transition duration-200'>
                <a href="https://calendly.com/m-sainiassociates/therapysession?month=2024-03" target="_blank" rel="noreferrer"> Get Started</a>
            </li>
        </ul>
      
    </div>
  )
}

export default Navbar
